import React from 'react';
import 'styles/spinner.scss';
import logoVR from 'assets/icons/logovr.svg';
import { createPortal } from 'react-dom';

const Spinner = () => {
    const isLoading = true;
    return createPortal(
        <div className={`spinner-container ${isLoading ? '' : 'hidden'}`}>
            {Array(12)
                .fill(null)
                .map((_, i) => {
                    return (
                        <div className="wrapper" key={i}>
                            <div />
                        </div>
                    );
                })}
            <img className="spinner-image" src={logoVR} />
        </div>,
        document.body
    );
};

export default Spinner;
