import axios from 'axios';

// const {
//     REACT_APP_BASE_URL,
//     REACT_APP_BASE_URL_DEV,
//     REACT_APP_NODE_ENV
// } = process.env;


//  baseURL: REACT_APP_NODE_ENV === 'production' ? REACT_APP_BASE_URL : REACT_APP_BASE_URL_DEV,

export const setAuthToken = (token) => {
    if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common["Authorization"];
    }
}