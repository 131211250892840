import React, { Suspense, lazy } from 'react';
import './styles/app.scss';
import AppRouter from './routes';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Spinner from './components/loading-bar/Spinner';
import reportWebVitals from './reportWebVitals';
import { set, setAuthToken } from 'config/axiosClient';

const token = localStorage.getItem('authToken');

if (token) {
    setAuthToken(token);
}

function App() {
    return (
        <div className="app">
            <Suspense fallback={<Spinner />}>
                <AppRouter />
            </Suspense>
            <ToastContainer
                containerId={'holonext-alert'}
                draggable={false}
                position={'top-center'}
                newestOnTop={true}
                pauseOnFocusLoss={false}
                style={{ fontSize: '1.2rem', width: '500px' }}
            />
        </div>
    );
}

export default App;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
